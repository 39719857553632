import React from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import {
  Paper,
  Box,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  Switch,
  Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import ThemeColors from "components/global/theme/theme_colors";
import { formatCurrency } from "utilities/functions/FormatCurrency";
import ArrowDownIcon from "icons/svgs/ArrrowDownIcon";

const PricingBreakdown = () => {
  const { hashedId } = useParams();

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  const handleToggle = (index: number) => {};

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="cardTitle" sx={{ mt: 1, mb: 1 }} component="div">
        Price Breakdown
      </Typography>
      <Stack>
        <Paper
          variant="outlined"
          square={true}
          sx={{
            bgcolor: ThemeColors.paperBackground,
            borderLeft: 0,
            borderRight: 0,
            padding: "12px 16px",
          }}
        >
          <Grid container gap={"16px"}>
            <Grid sx={{ flex: 1 }}>
              <Typography variant="gridTextLarge">Expected Buy Rate</Typography>
            </Grid>

            <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
              <Typography variant="gridTextBold">
                {formatCurrency(
                  quoteData.projectedCost?.companyRate?.targetBuyRate
                )}
              </Typography>
            </Grid>
            <Grid sx={{ flex: "0 0 50px" }}>
              {false && <Typography>Empty</Typography>}
            </Grid>
          </Grid>
        </Paper>
        <Paper
          variant="outlined"
          square={true}
          sx={{
            bgcolor: ThemeColors.paperBackground,
            borderLeft: 0,
            borderRight: 0,
            padding: "12px 16px",
          }}
        >
          <Grid container gap={"16px"}>
            <Grid sx={{ flex: 1 }}>
              <Typography variant="gridTextLarge">Markups</Typography>
            </Grid>
            <Grid sx={{ flex: "0 0 80px", textAlign: "right" }}>
              <Typography variant="gridTextBold">
                {`${quoteData.quoteTargetSellRateDto?.totalMarginPercent?.toFixed(2)}%`}
              </Typography>
            </Grid>
            <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
              <Typography variant="gridTextBold">
                {formatCurrency(
                  quoteData.quoteTargetSellRateDto?.totalMarginAmount
                )}
              </Typography>
            </Grid>
            <Grid sx={{ flex: "0 0 50px" }}>
              {false && <Typography>Empty</Typography>}
            </Grid>
          </Grid>
        </Paper>
        {quoteData.markups?.map((markup, markupIndex) => {
          return (
            <Grid
              container
              key={`markup_${markupIndex}`}
              gap={"16px"}
              sx={{
                padding: "12px 16px",
                borderBottom: "1px solid #E6E9F5",
                alignItems: "center",
              }}
            >
              <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                {markup.conditions?.map((c, cIndex) => {
                  return (
                    <Typography key={`condition_${cIndex}`} variant="gridText">
                      {c.fieldName}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid sx={{ flex: 1, flexFlow: "column" }} container>
                {markup.conditions?.map((c, cIndex) => {
                  return (
                    <Typography
                      key={`conditionValue_${cIndex}`}
                      variant="gridText"
                    >
                      {c.value}
                    </Typography>
                  );
                })}
              </Grid>

              <Grid sx={{ flex: "0 0 80px", textAlign: "right" }}>
                <Typography
                  variant="gridText"
                  sx={{
                    textDecoration: markup.enabled ? "none" : "line-through",
                  }}
                >
                  {`${markup.markupPercent?.toFixed(2)}%`}
                </Typography>
              </Grid>
              <Grid sx={{ flex: "0 0 100px", textAlign: "right" }}>
                <Typography
                  variant="gridText"
                  sx={{
                    textDecoration: markup.enabled ? "none" : "line-through",
                  }}
                >
                  {formatCurrency(markup.amount)}
                </Typography>
              </Grid>
              <Grid sx={{ flex: "0 0 50px", width: "50px" }}>
                <Switch
                  checked={markup.enabled}
                  onChange={() => {
                    handleToggle(markupIndex);
                    markup.enabled = !markup.enabled;
                  }}
                  style={{
                    textDecoration: "blue",
                    color: markup.enabled ? ThemeColors.textAction : "#F5F5F5",
                    //backgroundColor: ThemeColors.textAction,
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
        {/* <Grid
          gap={"16px"}
          sx={{
            padding: "12px 16px",
            borderBottom: "1px solid #E6E9F5",
            alignItems: "center",
          }}
        >
          Add Markup
        </Grid> */}
        <Paper
          variant="outlined"
          square={true}
          sx={{
            bgcolor: ThemeColors.paperBackground,
            borderLeft: 0,
            borderRight: 0,
            mt: 1,
            padding: "12px 16px",
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid sx={{ flexGrow: 1 }}>
              <Typography variant="gridTextLarge">Total</Typography>
            </Grid>
            <Grid sx={{ mr: "15px" }}>
              <ArrowDownIcon
                sx={{
                  color: ThemeColors.warning,
                  width: "36px",
                  height: "36px",
                }}
              />
            </Grid>
            <Grid container flexDirection="column">
              <Typography
                sx={{
                  color: "#302E2C",
                  fontSize: "20px",
                  lineHeight: "20px",
                }}
              >
                {formatCurrency(
                  quoteData.overriddenQuoteTargetSellRateDto?.targetSellRate ??
                    quoteData.quoteTargetSellRateDto?.targetSellRate
                )}
              </Typography>
              <Grid container>
                <Typography
                  sx={{
                    color: "#302E2C",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  ({" "}
                  {formatCurrency(
                    quoteData.overriddenQuoteTargetSellRateDto?.totalRpm ??
                      quoteData.quoteTargetSellRateDto?.totalRpm
                  )}{" "}
                  /
                </Typography>
                <Typography
                  sx={{
                    color: "#858BA0",
                    fontSize: "12px",
                    fontWeight: "400",
                    mx: "4px",
                  }}
                >
                  mile
                </Typography>
                <Typography
                  sx={{
                    color: "#302E2C",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  )
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Box>
  );
};

export default PricingBreakdown;
