import React from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import { Paper, Box, Stack, Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import PricingBreakdown from "./PricingBreakdown";
import RecommendedPricing from "./RecommendedPricing";

const QuoteInsights = () => {
  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  return (
    <Paper
      sx={{ background: "rgba(242, 242, 243, 0.45)", padding: "28px 12px" }}
    >
      <Grid container>
        <Grid size={{ xs: 12 }} sx={{ padding: "8px 4px" }}>
          <Paper
            sx={{
              background: "#ECF1F1",
              boxShadow: "0px 0px 2.8px 0px rgba(151, 150, 149, 0.24)",
            }}
          >
            <Grid container sx={{ padding: "8px 4px" }}>
              <Box
                sx={{ padding: "7px 10px", background: "#fff", width: "100%" }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    lineHeight: "20px",
                    color: "#302E2C",
                  }}
                >
                  Lane Insights
                </Typography>
              </Box>
            </Grid>
            <Grid sx={{ padding: "7px 10px" }}>
              <Typography>...</Typography>
              <Typography>...</Typography>
              <Typography>...</Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ padding: "8px 4px" }}>
          <Paper
            sx={{
              background: "#ECF1F1",
              boxShadow: "0px 0px 2.8px 0px rgba(151, 150, 149, 0.24)",
            }}
          >
            <Grid container sx={{ padding: "8px 4px" }}>
              <Box
                sx={{ padding: "7px 10px", background: "#fff", width: "100%" }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    lineHeight: "20px",
                    color: "#302E2C",
                  }}
                >
                  Similar Quotes
                </Typography>
              </Box>
            </Grid>
            <Grid sx={{ padding: "7px 10px" }}>
              <Typography>...</Typography>
              <Typography>...</Typography>
              <Typography>...</Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuoteInsights;
