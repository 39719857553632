import { ThemeOptions, Typography } from "@mui/material";
import { createTheme, ThemeProvider, Paper } from "@mui/material";

const ThemeColors = {
  paperBackground: "rgba(242, 242, 243, 0.45)",
  textBody: "#595856",
  textDark: "#000000",
  textAction: "#618888",
  textDisabled: "#979695",
  textHeadings: "#302E2C",
  warning: "#E37A41",

  confidenceMedium: "#FFE300",
  confidenceMediumEmpty: "#FFF6B5",

  metaforaGreen: "#396A6A",
};

export default ThemeColors;
