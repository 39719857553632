export const formatCurrency = (
  value?: number | string | undefined,
): string | null => {
  if (!value) return null;
  let UsDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return `${UsDollar.format(Number(value))}`;
};
