import React from "react";
// import Paper from '@mui/material/Paper'
import Grid from "@mui/material/Grid2";
import { Paper, Box, Stack, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { QuoteResponseDto } from "api/PricingPlatformApi.generated";
import PricingBreakdown from "./PricingBreakdown";
import RecommendedPricing from "./RecommendedPricing";

const PricingCombined = () => {
  const { hashedId } = useParams();

  const quoteData = useSelector<any, QuoteResponseDto>(
    (state: any) => state.quoteRequestReducer.quote
  );

  return (
    <Paper>
      <PricingBreakdown />
      <Divider sx={{ my: 2 }} variant="middle" />
      <RecommendedPricing />
    </Paper>
  );
};

export default PricingCombined;
