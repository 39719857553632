import React from 'react'
import { AccountDetailsProps } from '../Interfaces/interfaces'
import { Box, Paper, Typography } from '@mui/material'
import Grid from "@mui/material/Grid2";
import AccountDetailsCardInformation from './AccountDetailsCardInformation';

const AccountDetails:React.FC<AccountDetailsProps> = ({ quote }) => {
  return (
    <Box>
        <Paper>
            <Grid container spacing={1} direction="column" ml={2} mr={2}>
                <Typography variant="cardTitle" mt={1}>
                    Details
                </Typography>
                <AccountDetailsCardInformation quote={quote}/>
            </Grid>
        </Paper>
    </Box>
) 
}

export default AccountDetails